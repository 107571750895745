import { JIDO_SEISEI_FILE_APPROVED, UPDATE_PERMISSION } from "@kraftheinz/common";
import { D as DownloadModal } from "./DownloadModal.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("a-modal", { attrs: { "visible": _vm.isVisible, "width": "90vw", "dialog-style": { top: "0px" }, "dialog-class": "pdf-modal", "ok-text": "\u627F\u8A8D", "ok-button-props": {
    props: { disabled: _vm.isApproved, loading: _vm.isApproving }
  }, "cancel-text": "\u30AD\u30E3\u30F3\u30BB\u30EB" }, on: { "cancel": _vm.handleCancel, "ok": _vm.handleApproveFile } }, [_c("a-icon", { staticClass: "download-button", attrs: { "type": "download" }, on: { "click": _vm.openDownloadModal } }), _c("div", { staticClass: "pdf-container" }, [_c("iframe", { attrs: { "src": _vm.filePDF, "width": "100%", "height": "100%" } })]), _c("download-modal", { attrs: { "is-visible": _vm.isOpened }, on: { "download-click": _vm.downloadClick, "closed-modal": _vm.closedModal } })], 1)], 1);
};
var staticRenderFns = [];
var PDFReview_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => '.pdf-modal.ant-modal[data-v-16e47bb0]{padding-bottom:0!important}.pdf-modal .pdf-container[data-v-16e47bb0]{height:calc(100vh - 101px);width:100%;box-shadow:#0000003d 0 3px 8px;border-radius:5px;margin-top:20px;padding:10px;background:#fff;overflow-y:hidden}.pdf-modal .download-button[data-v-16e47bb0]{position:absolute;top:0;right:60px;color:#00000073;height:60px;width:100px;font-weight:700;line-height:60px;text-align:center;cursor:pointer}.pdf-modal .download-button[data-v-16e47bb0]:after{content:" Download";color:#000;padding-left:10px}\n')();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "PDFReview",
  components: { DownloadModal },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    base64: {
      type: String,
      default: ""
    },
    selectedRecord: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      filePDF: "",
      isOpened: false,
      approveFileUrl: `${apiUrl}/jidoseisei/fileapproved`,
      page: JIDO_SEISEI_FILE_APPROVED,
      permissions: {
        update: UPDATE_PERMISSION
      },
      isApproving: false
    };
  },
  computed: {
    isApproved() {
      return this.selectedRecord && this.selectedRecord.isApproved || !this.$can(this.permissions.update, this.page) ? true : false;
    }
  },
  watch: {
    base64(newVal) {
      if (newVal)
        this.uploadFile();
    }
  },
  methods: {
    uploadFile() {
      this.filePDF = `data:application/pdf;base64,${encodeURI(this.base64)}#toolbar=0`;
    },
    handleCancel() {
      this.$emit("closed-modal", false);
    },
    openDownloadModal() {
      this.isOpened = true;
    },
    closedModal() {
      this.isOpened = false;
    },
    downloadClick(typesOfFile) {
      const record = {
        record: this.selectedRecord,
        type: typesOfFile
      };
      this.$emit("on-download", record);
      this.closedModal();
      this.handleCancel();
    },
    async handleApproveFile() {
      this.isApproving = true;
      try {
        const payload = {
          id: this.selectedRecord.fileApprovedId,
          customerId: this.selectedRecord.id,
          isApproved: true
        };
        const res = await this.axios.put(this.approveFileUrl, payload);
        if (res && res.data) {
          this.$emit("closed-modal", false);
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.isApproving = false;
      }
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, "16e47bb0", null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var PDFReview = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { PDFReview as default };
